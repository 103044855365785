export const supervisorRules = {
  buffer_type_track: [
    {
      required: true,
      message: 'Выберете тип авто',
      trigger: 'blur',
    },
  ],
}

export const supervisorTableFields = [
  {
    prop: 'culture_id',
    label: 'Культура',
    width: '300',
    key: 0,
    fixed: true,
  },
  {
    prop: 'total_count',
    label: 'Всего',
    width: '100',
    key: 1,
  },
  {
    prop: 'waiting_truck_type',
    label: 'Дольше всех',
    key: 2,
    width: '175',
  },
  {
    prop: 'dump_truck_side_count',
    label: 'Cамосвал боковой',
    key: 3,
    width: '140',
  },
  {
    prop: 'dump_truck_back_count',
    label: 'Cамосвал задний',
    key: 3,
    width: '140',
  },
  {
    prop: 'boardsiders_count',
    label: 'Бортовые',
    key: 5,
    width: '140',
  },
]

export const supervisorTableFieldsMobile = [
  {
    prop: 'culture_id',
    label: 'Культура',
    // width: '180',
    key: 0,
    fixed: true,
  },
  {
    prop: 'total_count',
    label: 'Всего',
    width: '75',
    key: 1,
  },
  {
    prop: 'waiting_truck_type_mobile',
    label: 'Время',
    key: 2,
    width: '110',
  },
]
